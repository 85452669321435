import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"
import {
  DEPARTMENT_PER_PAGE,
  KEYMAN_RANK_TYPE,
  TOP_N_ITEMS,
} from "../constants/constants"
import ListLayout from "../components/common/list-layout"
import DepartmentItem from "../components/common/department-item"
import { Location } from "@reach/router"
import { getQueryVariable } from "../utils/str"
import Ranking from "../components/common/ranking"

const mapStateToProps = (state, ownProps) => {
  return {
    departments: state.departments.departments.toIndexedSeq().toArray(),
    pageInfo: state.departments.pageInfo_department,
    isLoading: state.departments.isLoadingDepartments,
    isLoadingDepartmentRank: state.departments.isLoadingDepartmentRank,
    letterRankStats: state.departments.letterRankStats,
    reactionRankStats: state.departments.reactionRankStats,
    shoudanRankStats: state.departments.shoudanRankStats,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    countForIndustyCustomPages: data => dispatch(actions.countForIndustyCustomPages(data)),
    getKeymansDepartments: data =>
      dispatch(actions.getKeymansDepartments(data)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
    getKeymansCount: () => dispatch(actions.getKeymansCount()),
    getRankDepartments: data => dispatch(actions.getRankDepartments(data)),
    getRankJsicLDepartments: data => dispatch(actions.getRankJsicLDepartments(data)),
    getRankCorporationDepartments: data => dispatch(actions.getRankCorporationDepartments(data)),
  }
}

const styles = theme => ({})

class Departments extends React.Component {
  componentDidMount() {
    const { path, location } = this.props
    const jsic_l_val = path ? path.match("industries/(.*)/departments") : ''
    const page_val = location && location.search ? location.search.match("page=(.*)"): ''

    // 業界と部門のかけあわせページの場合(/industries/{key}/departments)
    if (jsic_l_val) {
      this.props.countForIndustyCustomPages({ jsic_l: [decodeURI(jsic_l_val[1])], page: page_val ? parseInt(page_val[1]) : 1})
      this.props.getRankJsicLDepartments({ top_n: TOP_N_ITEMS, jsic_l:decodeURI(jsic_l_val[1]) })

    // かけあわせページ以外
    } else {
      this.props.getKeymansCount()
      this.props.getRankDepartments({ top_n: TOP_N_ITEMS })
    }
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
  }

  getQuery = (props, query = {}) => {
    return {
      paging: 1,
      per_page: DEPARTMENT_PER_PAGE,
      page: 1,
      ...getQueryVariable(props.location.search, ["page"]),
      ...query,
    }
  }

  render() {
    const {
      departments,
      isLoading,
      pageInfo,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      letterRankStats,
      reactionRankStats,
      shoudanRankStats,
      updateKeymans,
      isLoadingUpdateKeymans,
      path,
    } = this.props
    const jsic_l_val = path ? path.match("industries/(.*)/departments") : ''
    let customText = ''

    if (jsic_l_val) {
      customText = `${decodeURI(jsic_l_val[1])}の`
    }

    return (
      <ListLayout
        name={`${customText}部門`}
        title={`${customText}部門一覧`}
        ItemComponent={DepartmentItem}
        items={departments}
        isLoading={isLoading}
        perPage={DEPARTMENT_PER_PAGE}
        pageInfo={pageInfo}
        getItems={(data, overwrite) =>
          this.props.getKeymansDepartments(
            this.getQuery(this.props, data),
            overwrite
          )
        }
        keymanCountYstd={keymanCountYstd}
        updateKeymans={updateKeymans}
        isLoadingUpdateKeymans={isLoadingUpdateKeymans}
        keymansTotal={keymansTotal}
        corporationsTotal={corporationsTotal}
        path={path ? path.slice(1) : ''}
        isInitialized={true}
      >
        <Ranking
          title={`${customText}部門別レター送付率ランキング`}
          id="letter-rank"
          path={path}
          type={KEYMAN_RANK_TYPE.LETTER}
          items={letterRankStats}
        />
        <Ranking
          title={`${customText}部門別レター確認率ランキング`}
          id="reaction-rank"
          path={path}
          type={KEYMAN_RANK_TYPE.REACTION}
          items={reactionRankStats}
        />
        <Ranking
          title={`${customText}部門別レター商談達成率ランキング`}
          id="shoudan-ranking"
          path={path}
          type={KEYMAN_RANK_TYPE.SHOUDAN}
          items={shoudanRankStats}
        />
      </ListLayout>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Departments {...locationProps} {...props} />}
    </Location>
  ))
)
